<template>
  <div class="type-list">
    <l-title-card
        :name="$route.meta.title"
    >
      <el-button
          type="primary"
          icon="el-icon-plus"
          @click="addType"
      >
        添加
      </el-button>
    </l-title-card>
    <div class="main-content-container">
      <el-table
          :data="list"
          border
          stripe
      >
        <el-table-column
            label="序号"
            type="index"
            width="50px"
        >
        </el-table-column>
        <el-table-column
            label="分类名称"
            prop="name"
        >
        </el-table-column>
        <el-table-column
            label="文章数量"
            prop="articleNum"
        >
          <template slot-scope="scope">
            <span class="link">
              {{ scope.row.articleList.length }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
            label="创建时间"
        >
          <template slot-scope="scope">
            {{ $dayJS(scope.row.createTime).format("YYYY/MM/DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column
            label="更新时间"
        >
          <template slot-scope="scope">
            {{ $dayJS(scope.row.updateTime).format("YYYY/MM/DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            width="120px"
        >
          <template slot-scope="scope">
            <l-icon
                name="icon-bianji"
                class="edit"
                @click="editType(scope.row)"
            >
            </l-icon>
            <l-icon
                name="icon-shanchukai"
                class="delete"
                @click="deleteType(scope.row)"
            >
            </l-icon>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
        :visible.sync="showDialog"
        :title="type === 'edit' ? `编辑分类` : '添加分类'"
    >
      <el-form
          :model="dataInfo"
          label-width="80px"
          :rules="rules"
          ref="typeForm"
      >
        <el-form-item
            label="分类名称"
            prop="name"
        >
          <el-input
              v-model="dataInfo.name"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button
            type="primary"
            @click="saveData"
        >
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      list: [],
      dataInfo: {},
      showDialog: false,
      rules: {
        name: [
          {
            required: true,
            message: "请输入分类名称",
            trigger: "blur"
          }
        ]
      }
    }
  },
  watch: {
    showDialog(nVal) {
      if (!nVal) {
        setTimeout(() => {
          this.$refs.typeForm.resetFields();
        }, 10)
      }
    }
  },
  methods: {
    /**
     * @description 获取分类列表
     */
    async getTypeList() {
      let list = await this.$api.type.getTypeList({
        pageSize: 10,
        pageNo: 1
      })
      list.map(item => {
        console.log(item.name, new Date(item.createTime).getHours() + ":" + new Date(item.createTime).getSeconds())
      })
      this.list = list;
    },
    /**
     * @description 编辑分类
     * @param itemData
     */
    editType(itemData) {
      this.showDialog = true;
      this.dataInfo = Object.assign({}, itemData);
      this.type = "edit"
    },
    /**
     * @description 删除分类
     * @param itemData
     */
    deleteType(itemData) {
      this.$confirm(`你确定要删除${itemData.name}?`, "温馨提示", {
        type: "warning"
      }).then(async () => {
        const result = await this.$api.type.deleteTypeInfo({
          id: itemData.id
        });
        if (result) {
          this.$notify({
            type: "success",
            title: "操作成功",
            message: `${itemData.name}已删除成功`
          })
          this.getTypeList()
        }
      }).catch(() => {
        console.warn("放弃执行了")
      })
    },
    /**
     * @description 添加分类数据信息
     */
    addType() {
      this.showDialog = true;
      this.dataInfo = {
        name: ""
      }
      this.type = "add"
    },
    /**
     * @description 保存数据信息
     */
    saveData() {
      this.$refs.typeForm.validate(async valid => {
        if (valid) {
          if (this.type === "edit") {
            await this.updateTypeInfo();
          } else {
            await this.addNewTypeInfo();
          }
          this.showDialog = false;
          this.getTypeList()
        }
      })
    },
    /**
     * @description 执行
     */
    async updateTypeInfo() {
      const result = await this.$api.type.editTypeInfo(this.dataInfo);
      if (result) {
        this.$notify({
          type: "success",
          title: "编辑分类信息成功"
        })
      }
    },
    /**
     * @description
     * @return {Promise<void>}
     */
    async addNewTypeInfo() {
      const result = await this.$api.type.addTypeInfo(this.dataInfo);
      if (result) {
        this.$notify({
          type: "success",
          title: "新增分类信息成功"
        })
      }
    }
  },
  created() {
    this.getTypeList();
  }
}
</script>

<style lang="scss">

</style>
